import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notificacion } from 'src/app/core/models/notificacion';
import { CuentasService } from 'src/app/services/servicio.cuenta';
import { NotificacionesService } from 'src/app/services/servicio.notificaciones';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: string;
  password: string;
  estaAutentificando: boolean;

  constructor(
    private router: Router, 
    private cuentaService: CuentasService,
    private servicioNotificaciones: NotificacionesService) { }

  ngOnInit() {
    this.estaAutentificando = false;
  }

  ingresar() : void {
    this.estaAutentificando = true;
    this.cuentaService.login(this.user, this.password).subscribe(response => {
      if(response.esCorrecto)
      {
        this.cuentaService.establecerSesion(response.datos);
        this.router.navigate(['home/clientes/cliente-lista']);
      }
      else {
        console.log(response);
        var notificacion = new Notificacion("Encontramos algunos errores", response.mensajes[0], "Error");
        this.servicioNotificaciones.agregarNotificacion(notificacion);
      }
      this.estaAutentificando = false;
    },
    error => {
      console.log(error);
      var notificacion = new Notificacion("Ups, algo anda mal...", error.message, "Error");
      this.servicioNotificaciones.agregarNotificacion(notificacion);
      this.estaAutentificando = false;
    });
  }
 

}
