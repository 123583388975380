import { Component, OnInit } from '@angular/core';
import { Notificacion } from '../../core/models/notificacion';
import { NavigationEnd, Router } from '@angular/router';
import { NotificacionesService } from 'src/app/services/servicio.notificaciones';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  notificaciones: Notificacion[] = [];

  constructor(
              private servicioNotificaciones: NotificacionesService,
              private router: Router) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.notificaciones = [];
      }
    });
    this.servicioNotificaciones.notificacionAgregada$.subscribe(
      notificacion => {
          this.notificaciones.push(notificacion);
          setTimeout(() => {
            this.cerrarNotificacion(notificacion);
          }, (notificacion.Nivel != "Advertencia" ? 5000 : 20000));
      })
  }

  ngOnInit() {
  }
  
  cerrarNotificacion(notificacion: any): void{
    this.notificaciones = this.notificaciones.filter(n => n != notificacion);
  }
}
