import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CuentasService {
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headers(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        };

        return new HttpHeaders(headerDict);
    }

    private headersAutentificados(): any {
        const sessionCondor = this.obtenerSesion();

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': sessionCondor != null ? sessionCondor.token : '00000000-0000-0000-0000-000000000000'
        };

        return new HttpHeaders(headerDict);
    }

    public login(email: string, password: string): Observable<any> {
        const options = {
            headers: this.headers()
        };
        localStorage.clear();
        const usuario = { Email: email, Contrasena: password };
        return this.http.post(`${this.urlApi}/Cuenta/Ingresar`, usuario, options);
    }

    public establecerSesion(sesion): void {
        localStorage.setItem('sessionCondor', JSON.stringify(sesion));
    }

    public cerrarSesion(): Observable<any> {
        const options =  {
            headers: this.headersAutentificados()
        };
        const datos = {  };
        localStorage.clear();
        return this.http.post(`${this.urlApi}/SesionApi/CerrarSesion`, datos, options);
    }

    public estaActiva(): Observable<any> {
        const options =  {
            headers: this.headersAutentificados()
        };
        return this.http.get(`${this.urlApi}/SesionApi/EstaActiva`, options);
    }

    public refrescarSesion(): Observable<any> {
        const options =  {
            headers: this.headersAutentificados()
        };
        return this.http.get(`${this.urlApi}/SesionApi/RefrescarSesion`, options);
    }

    public obtenerSesion(): any {
        const sessionCondor = localStorage.getItem('sessionCondor');
        if (sessionCondor != null) {
            return JSON.parse(sessionCondor);
        }
        return null;
    }

    public obtenerSesionDesdeBackend(): any {
        const options =  {
            headers: this.headersAutentificados()
        };
        const datos = {  };
        return this.http.post(`${this.urlApi}/SesionApi/ObtenerSesion`, datos, options);
    }
}
