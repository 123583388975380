import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteListaComponent } from './cliente-lista/cliente-lista.component';
import { ClienteDetalleComponent } from './cliente-detalle/cliente-detalle.component';
import { ClientesService } from '../services/servicio.clientes';
import { ClienteModificarComponent } from './cliente-modificar/cliente-modificar.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { EnviarReporteBoletaDialogoComponent } from './cliente-detalle/dialogos/enviar-reporte-boleta-dialogo/enviar-reporte-boleta-dialogo.component';
import { ClienteEliminarComponent } from './cliente-eliminar/cliente-eliminar.component';

@NgModule({
  declarations: [ClienteListaComponent, ClienteDetalleComponent, ClienteModificarComponent, EnviarReporteBoletaDialogoComponent, ClienteEliminarComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule
  ],
  providers: [ClientesService]
})
export class ClientesModule { }
