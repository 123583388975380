import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ClientesService } from 'src/app/services/servicio.clientes';
import { InfoPopupComponent } from 'src/app/shared/info-popup/info-popup.component';
import { EnviarReporteBoletaDialogoComponent } from './dialogos/enviar-reporte-boleta-dialogo/enviar-reporte-boleta-dialogo.component';

@Component({
  selector: 'app-cliente-detalle',
  templateUrl: './cliente-detalle.component.html',
  styleUrls: ['./cliente-detalle.component.css']
})
export class ClienteDetalleComponent implements OnInit {

  public cliente: any;
  public alertas: Array<any>;
  public enviosFolios: Array<any>;
  public alertaTemporal: any;

  public agregarAlertaClienteVisible: boolean;

  constructor(
    private clientesService: ClientesService,
    private route: ActivatedRoute,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    var id = 1;
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.clientesService.getClientePorRut(id).subscribe(res => {
        this.cliente = res.datos;
        this.clientesService.getAlertas(this.cliente.alias).subscribe(respuesta => {
          this.alertas = respuesta.datos;
          console.log(this.alertas);
        });
        //obtiene los ultimos 10 envios de folios
        this.clientesService.getAccionesPorTipo(this.cliente.alias, 0, 10).subscribe(respuesta => {
          this.enviosFolios = respuesta.datos;
          console.log(this.enviosFolios);
        });
        console.log(this.cliente);
      });
    });
  }

  eliminarAlerta(alerta: any): void {
    this.clientesService.eliminarAlerta(alerta).subscribe(res => {
      this.clientesService.getAlertas(this.cliente.alias).subscribe(respuesta => {
        this.alertas = respuesta.datos;
        console.log(this.alertas);
      });
    });
  }

  crearAlerta() : void {
    this.utilizarTemplate(-1);
    this.agregarAlertaClienteVisible = !this.agregarAlertaClienteVisible;
  }

  agregarAlerta() : void {
    this.clientesService.agregarAlerta(this.alertaTemporal).subscribe(res => {
      this.clientesService.getAlertas(this.cliente.alias).subscribe(respuesta => {
        this.alertas = respuesta.datos;
        console.log(this.alertas);
        this.utilizarTemplate(-1);
        this.agregarAlertaClienteVisible = !this.agregarAlertaClienteVisible;
      });
    });
  }

  verMensaje(alerta: any) : void {
    let dialogRef = this.dialog.open(InfoPopupComponent, { 
      height: '400px',
      width: '70%',
      data: { html: alerta.mensaje, titulo: alerta.titulo },
    });
  }

  utilizarTemplate(opcionSeleccionada: number): void {
    switch (opcionSeleccionada) {
      case 0:
        this.alertaTemporal = {
          mensaje: "Estimado cliente, le informamos que posee la mensualidad de __________ pendiente de pago por un total de $_________, por favor regularice su situación para evitar la suspensión del servicio. <a target='_blank' href='http://www.condorerp.cl/como-puedo-pagar-mi-cuenta/'>¿Cómo puedo pagar mi mensualidad?</a>. Saludos cordiales",
          tipo: 1,
          titulo: "Mensualidad Pendiente"
        };
        break;
      case 1:
        this.alertaTemporal = {
          mensaje: "Estimado cliente, le informamos que realizaremos una actualización de su sistema, por favor evite realizar operaciones mientras vea este mensaje. Muchas gracias. Saludos cordiales",
          tipo: 1,
          titulo: "Mantención Programada"
        };
        break;
      default:
        this.alertaTemporal = {
          mensaje: "",
          tipo: 1,
          titulo: ""
        };
        break;
    };
    this.alertaTemporal.cliente = this.cliente;
    this.alertaTemporal.fechaCreacion = new Date();
    this.alertaTemporal.fechaExpiracion = new Date();
  }

  dialogoEnviarReporteBoleta() : void {
    const dialogRef = this.dialog.open(EnviarReporteBoletaDialogoComponent, {
      width: '50%',
      data: this.cliente
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.result && res.result.response == 'ok') {
        console.log(res);
        this.enviarReporteBoleta(res.result.cliente, res.result.dia, res.result.mes, res.result.anio);
      }
      else
      {
        console.log('The dialog was closed');
      }
    });
  }

  enviarReporteBoleta(cliente: any, dia: number, mes: number, anio: number) : void {
    this.clientesService.envioReporteBoletas(cliente.alias, new Date(anio, mes, dia)).subscribe(res => {
      alert("Reporte enviado");
    });
  }

  calcularDiasDesde(desde: string): number {
    console.log(desde);
    var diff = Math.abs(new Date(desde).getTime() - new Date().getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    return diffDays;
  }
}
