import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientesService } from 'src/app/services/servicio.clientes';
import { TransaccionesService } from 'src/app/services/servicio.transacciones';

@Component({
  selector: 'app-transaccion-lista',
  templateUrl: './transaccion-lista.component.html',
  styleUrls: ['./transaccion-lista.component.css']
})
export class TransaccionListaComponent implements OnInit {
  public anioSeleccionado: number;
  public pagos: Array<any>;
  public egresos: Array<any>;
  public clientes: Array<any>;
  public aniosPosibles: Array<any>;
  public agregarTransaccionVisible: boolean = false;
  public transaccionTemporal: any;

  constructor(
    private transaccionesService: TransaccionesService,
    private clientesService: ClientesService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.anioSeleccionado = params['anio'];
      this.clientes = [];
      this.clientesService.getClientes().subscribe(res => {
        this.clientes = res.datos;
        console.log(this.clientes);
      });
      this.aniosPosibles = [];
      let anioActual = new Date().getFullYear();
      for (let i=0; i<3; i++){
        this.aniosPosibles.push(anioActual - i);
      }
      this.transaccionTemporal = {
        tipo: 0,
        formaDePago: 2, 
        monto: 0, 
        periodoMes: 1, 
        periodoAnio: new Date().getFullYear(), 
        fechaPago: new Date(), 
        cliente: {rut: -1},
        numeroDocumento: 0,
        observaciones: ''
      };
      this.refrescarPagos();
      this.refrescarEgresos();
    });
  }

  refrescarPagos() : void {
    this.pagos = [];
    this.transaccionesService.getPagos(this.anioSeleccionado).subscribe(res => {
      this.pagos = res.datos;
      console.log(this.pagos);
    });
  }
  
  refrescarEgresos() : void {
    this.egresos = [];
    this.transaccionesService.getEgresos(this.anioSeleccionado).subscribe(res => {
      this.egresos = res.datos;
      console.log(this.pagos);
    });
  }
  
  agregar() : void {
    if (this.transaccionTemporal.tipo == 1) {
      this.transaccionesService.agregarPago(this.transaccionTemporal).subscribe(res => {
        this.refrescarPagos();
        this.agregarTransaccionVisible = !this.agregarTransaccionVisible;
      });
    } 
    else {
      this.transaccionesService.agregarEgresos(this.transaccionTemporal).subscribe(res => {
        this.refrescarEgresos();
        this.agregarTransaccionVisible = !this.agregarTransaccionVisible;
      });
    }
  }

  filtrar(desde: Date, hasta: Date) : void {

  }

  totalPagos() : number {
    let suma = 0;
    for (let a=0; a<this.pagos.length; a++){
      suma = suma + this.pagos[a].monto;
    }
    return suma;
  }

  totalEgresos() : number {
    let suma = 0;
    for (let a=0; a<this.egresos.length; a++){
      suma = suma + this.egresos[a].monto;
    }
    return suma;
  }

  eliminarPago(pago: any) : void {
    this.transaccionesService.eliminarPago(pago).subscribe(res => {
      this.refrescarPagos();
    });
  }

  eliminarEgreso(egreso: any) : void {
    this.transaccionesService.eliminarEgresos(egreso).subscribe(res => {
      this.refrescarEgresos();
    });
  }

  anioCambiado() : void {
    this.refrescarEgresos();
    this.refrescarPagos();
  }

}
