import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AyudaService } from 'src/app/services/servicio.ayuda';

@Component({
  selector: 'app-ayuda-modificar',
  templateUrl: './ayuda-modificar.component.html',
  styleUrls: ['./ayuda-modificar.component.css']
})
export class AyudaModificarComponent implements OnInit {

  articulo: any; 
  constructor(
    private ayudaService: AyudaService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    var id = 1;
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.ayudaService.getArticulo(id).subscribe(res => {
        this.articulo = res.datos;
      });
    });
  }

  guardar() : void {
    debugger;
    this.ayudaService.modificar(this.articulo).subscribe(res => {
      this.articulo = res.datos;
      this.router.navigate(['/home/ayuda/ayuda-detalle', this.articulo.id]);
    });
  }
}
