<!-- Header -->
<div class="header bg-primary pb-6">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h2 class="h2 text-white d-inline-block mb-0">Clientes</h2>
                    <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item"><a href="#"><i class="fas fa-home"></i></a></li>
                <li class="breadcrumb-item"><a href="#">Tables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Tables</li>
              </ol>
            </nav>-->
                </div>
                <div class="col-lg-6 col-5 text-right">
                    <a href="#" class="btn btn-lg btn-neutral">Nuevo</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--6">
    <div class="row">
        <div class="col">
            <div class="card">
                <!-- Card header -->
                <div class="card-header border-0">
                    <h3 class="mb-0">Instancias de Condor ERP</h3>
                    <p>Estas son las instancias instaladas de Condor ERP</p>
                </div>

                <!-- Light table -->
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">RUT</th>
                                <th scope="col">Instancia</th>
                                <th scope="col">Plan</th>
                                <th scope="col">¿Esta Bloqueado?</th>
                                <th scope="col">Email</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="list">
                            <tr *ngFor="let cliente of filtrarPorEstado(this.clientes, false)">
                                <th scope="row">
                                    <div class="align-items-center">
                                        {{ cliente.rut }}
                                    </div>
                                </th>
                                <td class="budget">
                                    {{ cliente.alias }}
                                </td>
                                <td>
                                    {{ cliente.plan }}
                                </td>
                                <td>
                                    <span *ngIf="cliente.estaBloqueado" class="badge badge-dot mr-4">
                                        <i class="bg-warning"></i>
                                        <span class="status">Bloqueado</span>
                                    </span>
                                    <span *ngIf="!cliente.estaBloqueado" class="badge badge-dot mr-4">
                                        <i class="bg-success"></i>
                                        <span class="status">Activo</span>
                                    </span>
                                </td>
                                <td>
                                    {{ cliente.email }}
                                </td>
                                <td class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                                        Acciones <span class="fa fa-chevron-down"></span>
                                    </button>
                                    <mat-menu #menuAcciones="matMenu">
                                        <button mat-menu-item [routerLink]="['/home/clientes/cliente-detalle', cliente.rut]"><i class="fa fa-info-circle"></i> Detalles</button>
                                        <button mat-menu-item [routerLink]="['/home/clientes/cliente-modificar', cliente.rut, 'modificar']"><i class="fa fa-edit"></i> Modificar</button>
                                        <button mat-menu-item [routerLink]="['/home/clientes/cliente-eliminar', cliente.rut]"><i class="fa fa-trash"></i> Eliminar</button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- paginacion -->
                <!-- <div class="card-footer py-4">
                    <nav aria-label="...">
                        <ul class="pagination justify-content-end mb-0">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1">
                                    <i class="fas fa-angle-left"></i>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="fas fa-angle-right"></i>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div> -->

                <a style="padding: 10px 10px 10px 10px;" *ngIf="!mostrarInactivas" (click)="mostrarInactivas = !mostrarInactivas">Mostrar inactivas</a>
                <div *ngIf="mostrarInactivas">
                    <div class="card-header border-0">
                        <h3 class="mb-0">Instancias Inactivas</h3>
                        <p>Estas son las instancias inactivas, historicas de condor.</p>
                    </div>

                    <!-- Light table -->
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">RUT</th>
                                    <th scope="col">Instancia</th>
                                    <th scope="col">Plan</th>
                                    <th scope="col">¿Esta Bloqueado?</th>
                                    <th scope="col">Email</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr *ngFor="let cliente of filtrarPorEstado(this.clientes, true)">
                                    <th scope="row">
                                        <div class="align-items-center">
                                            {{ cliente.rut }}
                                        </div>
                                    </th>
                                    <td class="budget">
                                        {{ cliente.alias }}
                                    </td>
                                    <td>
                                        {{ cliente.plan }}
                                    </td>
                                    <td>
                                        <span *ngIf="cliente.estaBloqueado" class="badge badge-dot mr-4">
                                            <i class="bg-warning"></i>
                                            <span class="status">Bloqueado</span>
                                        </span>
                                        <span *ngIf="!cliente.estaBloqueado" class="badge badge-dot mr-4">
                                            <i class="bg-success"></i>
                                            <span class="status">Activo</span>
                                        </span>
                                    </td>
                                    <td>
                                        {{ cliente.email }}
                                    </td>
                                    <td class="text-right">
                                        <button type="button" class="btn btn-sm btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                                            Acciones <span class="fa fa-chevron-down"></span>
                                        </button>
                                        <mat-menu #menuAcciones="matMenu">
                                            <button mat-menu-item [routerLink]="['/home/clientes/cliente-detalle', cliente.rut]"><i class="fa fa-info-circle"></i> Detalles</button>
                                            <button mat-menu-item [routerLink]="['/home/clientes/cliente-modificar', cliente.rut, 'modificar']"><i class="fa fa-edit"></i> Modificar</button>
                                        </mat-menu>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- paginacion -->
                    <!-- <div class="card-footer py-4">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-end mb-0">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1">
                                        <i class="fas fa-angle-left"></i>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <i class="fas fa-angle-right"></i>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
