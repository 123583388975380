<div *ngIf="this.cliente">
    <!-- Header -->
    <div class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h2 class="h2 text-white d-inline-block mb-0">Modificando Cliente: {{ this.cliente.alias }}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2 card-header">Información del Cliente</h2>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>RUT</label>
                                    <div>{{ this.cliente.rut }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input type="text" name="nombre" class="form-control" [(ngModel)]="this.cliente.nombre" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Alias</label>
                                    <input type="text" name="alias" class="form-control" [(ngModel)]="this.cliente.alias" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Plan</label>
                                    <input type="text" name="plan" class="form-control" [(ngModel)]="this.cliente.plan" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>¿Está bloqueado?</label>
                                    <select name="estaBloqueado" class="form-control"
                                        [(ngModel)]="this.cliente.estaBloqueado">
                                        <option [ngValue]="true">Si</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" name="Email" class="form-control" [(ngModel)]="this.cliente.email" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Dirección</label>
                                    <input type="text" name="direccion" class="form-control" [(ngModel)]="this.cliente.direccion" />
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="card-body">
                            <button type="button" (click)="guardar()" class="btn btn-info btn-fill pull-right">Guardar</button>
                            <button type="button" [routerLink]="['/home/clientes/cliente-detalle', cliente.rut]" class="btn btn-default" >Cerrar</button>
                            <div class="clearfix"></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
