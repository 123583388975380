import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/servicio.clientes';
import { ScriptsService } from 'src/app/services/servicio.scripts';

@Component({
  selector: 'app-ejecutar-script',
  templateUrl: './ejecutar-script.component.html',
  styleUrls: ['./ejecutar-script.component.css']
})
export class EjecutarScriptComponent implements OnInit {

  public scripts: Array<any>;
  public clientes: Array<any>;
  public scriptSeleccionado: string;
  public mostrarClientes: boolean;
  public clienteSeleccionado: any;
  public ultimoResultado: any;

  constructor(
    private clientesService: ClientesService,
    private scriptsService: ScriptsService
  ) { }

  ngOnInit(): void {
    this.clientesService.getClientes().subscribe(res => {
      this.clientes = res.datos;
      console.log(this.clientes, "clientes");
    });

    this.scripts = [
        { nombre: "CrearModelo", requiereCliente: true },
        { nombre: "CrearLogica", requiereCliente: true },
        { nombre: "LimpiarInstancia", requiereCliente: true },
        { nombre: "SetUpInicial", requiereCliente: true },
        { nombre: "EjecutarCambiosParaBE", requiereCliente: true },
        { nombre: "CrearModeloAPI" },
        { nombre: "CrearLogicaAPI" },
        { nombre: "AgregarDatosResolucionBE", requiereCliente: true },
        { nombre: "EnviarConsumoFolios", requiereCliente: true },
        { nombre: "PrepararBDParaRegistrarConsumoFolios", requiereCliente: true },
        { nombre: "RevisarRutEnvioDte" },
        { nombre: "RevisarCorreoIntercambio" },
        { nombre: "LimpiarSesionesExpiradas", requiereCliente: true }
    ];

    console.log(this.scripts, "scripts");
    
  }

  public ejecutar() : void {
    console.log(this.scriptSeleccionado, this.clienteSeleccionado);
    var script = {
      aliasCliente: this.clienteSeleccionado,
      nombreScript: this.scriptSeleccionado
    };
    this.scriptsService.ejecutar(script).subscribe(res => this.ultimoResultado = res);
  }

  public onScriptChange(val: any) : void {
    var script = this.scripts.find(s => s.nombre == val);
    this.mostrarClientes = script.requiereCliente == true;
  }
}
