<div *ngIf="this.articulo">
    <!-- Header -->
    <div class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h2 class="h2 text-white d-inline-block mb-0">{{ this.articulo.titulo }}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2 card-header">
                                <span *ngIf="!articulo.habilitado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-warning"></i> {{this.articulo.titulo}}</h3>

                                </span>
                                <span *ngIf="articulo.habilitado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-success"></i>  {{this.articulo.titulo}}</h3>

                                </span>

                            </h2>
                        </div>
                        
                        <div class="col-lg-12">
                            <div class="card-body">
                                <div [innerHTML]="this.articulo.texto"></div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card-body">
                                <button class="btn-neutral" [routerLink]="['/home/ayuda/ayuda-lista']">Volver</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>