import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AyudaListaComponent } from './ayuda-lista/ayuda-lista.component';
import { AyudaDetalleComponent } from './ayuda-detalle/ayuda-detalle.component';
import { AyudaService } from '../services/servicio.ayuda';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { AyudaModificarComponent } from './ayuda-modificar/ayuda-modificar.component';

@NgModule({
  declarations: [AyudaListaComponent, AyudaDetalleComponent, AyudaModificarComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule
  ],
  providers: [AyudaService]
})
export class AyudaModule { }
