import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AyudaService {
    
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
          
        return new HttpHeaders(headerDict);
    }

    
    public getArticulos(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Ayuda/Todas?filtro=${filtro}&pagina=${pagina}&largoPagina=${largoPagina}`, options);
    }

    public getArticulo(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Ayuda/Articulo?id=${id}`, options);
    }

    public modificar(articulo: any)  : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Ayuda/Modificar`, articulo, options);
    }
}