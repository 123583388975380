import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-enviar-reporte-boleta-dialogo',
  templateUrl: './enviar-reporte-boleta-dialogo.component.html',
  styleUrls: ['./enviar-reporte-boleta-dialogo.component.css']
})
export class EnviarReporteBoletaDialogoComponent implements OnInit {
  public dia: number;
  public mes: number;
  public anio: number;

  constructor(public dialogRef: MatDialogRef<EnviarReporteBoletaDialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public cliente: any) { }

  ngOnInit(): void {
    this.anio = new Date().getFullYear();
    this.mes = new Date().getMonth() + 1;
    this.dia = new Date().getDate();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
