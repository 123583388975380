import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ScriptsService {
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headers(): any {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        };

        return new HttpHeaders(headerDict);
    }

    private headersAutentificados(): any {
        const sessionCondor = this.obtenerSesion();

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': sessionCondor != null ? sessionCondor.token : '00000000-0000-0000-0000-000000000000'
        };

        return new HttpHeaders(headerDict);
    }

    private obtenerSesion(): any {
        const sessionCondor = localStorage.getItem('sessionCondor');
        if (sessionCondor != null) {
            return JSON.parse(sessionCondor);
        }
        return null;
    }

    public ejecutar(script: any): Observable<any> {
        const options =  {
            headers: this.headersAutentificados()
        };
        return this.http.post(`${this.urlApi}/Scripts/Ejecutar`, script, options);
    }

}
