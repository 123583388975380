import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from 'src/app/services/servicio.clientes';

@Component({
  selector: 'app-cliente-eliminar',
  templateUrl: './cliente-eliminar.component.html',
  styleUrls: ['./cliente-eliminar.component.css']
})
export class ClienteEliminarComponent implements OnInit {

  public cliente: any;

  constructor(
    private clientesService: ClientesService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.clientesService.getClientePorRut(id).subscribe(res => {
        this.cliente = res.datos;
        console.log(this.cliente);
      });
    });
  }

  eliminar() : void {
    console.log("eliminando");
    this.clientesService.eliminarCliente(this.cliente).subscribe(res => {
      if (res.esCorrecto){
        this.regresar();
      }
      else {

      }
    });
  }

  regresar() : void {
    this.router.navigate(['/home/clientes/cliente-lista']);
  }
}
