import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClientesService {
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
          
        return new HttpHeaders(headerDict);
    }

    
    public getClientes() : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/Todos`, options);
    }

    public getCliente(alias: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/Seleccionar?alias=${alias}`, options);
    }

    public modificarCliente(cliente: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Cliente/Modificar`, cliente, options);
    }

    public eliminarCliente(cliente: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Cliente/Eliminar`, cliente, options);
    }
    
    public getClientePorRut(rut: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/SeleccionarPorRut?rut=${rut}`, options);
    }

    public getAccionesPorTipo(alias: string, tipo: number, cantidad: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/UltimasAcciones?alias=${alias}&tipo=${tipo}&cantidad=${cantidad}`, options);
    }

    public getAlertas(alias: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Cliente/Alertas?alias=${alias}`, options);
    }

    public agregarAlerta(alerta: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Cliente/AgregarAlerta`, alerta, options);
    }

    public eliminarAlerta(alerta: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Cliente/EliminarAlerta`, alerta, options);
    }

    public envioReporteBoletas(alias: string, fecha: Date) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Cliente/EnvioReporteBoletas`, { alias: alias, fecha: fecha }, options);
    }
}