import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from 'src/app/services/servicio.clientes';

@Component({
  selector: 'app-cliente-modificar',
  templateUrl: './cliente-modificar.component.html',
  styleUrls: ['./cliente-modificar.component.css']
})
export class ClienteModificarComponent implements OnInit {

  public cliente: any;

  constructor(
    private clientesService: ClientesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    var id = 1;
    this.route.params.subscribe(params => {
      var id = params['id'];
      var accion = params['accion'];
      if (accion == 'modificar') {
        this.clientesService.getClientePorRut(id).subscribe(res => {
          this.cliente = res.datos;
          console.log(this.cliente);
        });
      }
      else {
        console.log("accion no implementada");
      }
    });
  }

  guardar() : void {
    this.clientesService.modificarCliente(this.cliente).subscribe((res) => {
      console.log(res);
      if (res.esCorrecto){
        this.router.navigate(['/home/clientes/cliente-detalle', this.cliente.rut]);
      }
    });
  }
}
