<div *ngIf="this.cliente">
    <!-- Header -->
    <div class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h2 class="h2 text-white d-inline-block mb-0">Cliente: {{ this.cliente.alias }}</h2>
                    </div>
                    <div class="col-lg-6 col-5 text-right">
                        <button type="button" class="btn btn-sm btn-secondary" mat-button
                            [matMenuTriggerFor]="menuAcciones">
                            Acciones <span class="fa fa-chevron-down"></span>
                        </button>
                        <mat-menu #menuAcciones="matMenu">
                            <button mat-menu-item (click)="this.dialogoEnviarReporteBoleta()"><i
                                    class="fa fa-info-circle"></i> Envio Reporte de Boletas</button>
                            <button mat-menu-item
                                [routerLink]="['/home/clientes/cliente-modificar', cliente.rut, 'modificar']"><i
                                    class="fa fa-edit"></i> Modificar</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2 card-header">Información del Cliente</h2>
                        </div>
                        <div class="col-lg-6">
                            <!-- Card header -->
                            <div class="card-header border-0">
                                <p>RUT</p>
                                <h3 class="mb-0">{{ this.cliente.rut }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Nombre</p>
                                <h3 class="mb-0">{{ this.cliente.nombre }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Alias</p>
                                <h3 class="mb-0">{{ this.cliente.alias }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Plan</p>
                                <h3 class="mb-0">{{ this.cliente.plan }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>¿Está bloqueado?</p>

                                <span *ngIf="cliente.estaBloqueado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-warning"></i> Si</h3>

                                </span>
                                <span *ngIf="!cliente.estaBloqueado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-success"></i> No</h3>

                                </span>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Email</p>
                                <h3 class="mb-0">{{ this.cliente.email }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Dirección</p>
                                <h3 class="mb-0">{{ this.cliente.direccion ? this.cliente.direccion : '-' }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card-header border-0">
                                <h2 class="h2">Alertas</h2>
                                <div>

                                    <div class="table-responsive">
                                        <table class="table align-items-center table-flush">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Creación</th>
                                                    <th scope="col">Titulo</th>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody class="list">
                                                <tr *ngFor="let alerta of this.alertas">
                                                    <th scope="row">
                                                        <div class="align-items-center">
                                                            {{ alerta.fechaCreacion }}
                                                        </div>
                                                    </th>
                                                    <td class="budget">
                                                        {{ alerta.titulo }}
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="alerta.tipo == 0">Importante</ng-container>
                                                        <ng-container *ngIf="alerta.tipo == 1">Recordatorio
                                                        </ng-container>
                                                        <ng-container *ngIf="alerta.tipo == 2">Ayuda</ng-container>
                                                    </td>
                                                    <td class="text-right">
                                                        <button type="button" class="btn btn-sm btn-primary" mat-button
                                                            [matMenuTriggerFor]="menuAcciones">
                                                            Acciones <span class="fa fa-chevron-down"></span>
                                                        </button>

                                                        <mat-menu #menuAcciones="matMenu">
                                                            <button mat-menu-item (click)="verMensaje(alerta)"><i
                                                                    class="fa fa-info-circle"></i> Ver mensaje</button>
                                                            <button mat-menu-item (click)="eliminarAlerta(alerta)"><i
                                                                    class="fa fa-trash"></i> Eliminar</button>
                                                        </mat-menu>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br />
                                <a class="btn btn-neutral" *ngIf="!agregarAlertaClienteVisible"
                                    (click)="crearAlerta()">Nueva alerta</a>
                                <div class="row" *ngIf="agregarAlertaClienteVisible">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Tipo</label>
                                            <select name="Tipo" class="form-control"
                                                [(ngModel)]="this.alertaTemporal.tipo">
                                                <option [ngValue]="0">Importante</option>
                                                <option [ngValue]="1">Recordatorio</option>
                                                <option [ngValue]="2">Ayuda</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Titulo</label>
                                            <input type="text" name="Titulo" class="form-control"
                                                [(ngModel)]="this.alertaTemporal.titulo" placeholder="Titulo" value="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Mensaje</label>
                                            <textarea rows="10" name="Mensaje" class="form-control"
                                                placeholder="Mensaje"
                                                [(ngModel)]="this.alertaTemporal.mensaje"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <p>
                                            Utilizar Template:
                                            <a (click)="utilizarTemplate(0)" name="template">Mensualidad
                                                Pendiente</a>,
                                            <a (click)="utilizarTemplate(1)" name="template">Mantención
                                                Programada</a>
                                        </p>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" (click)="agregarAlerta()"
                                            class="btn btn-info btn-fill pull-right">Guardar</button>
                                        <button type="button" class="btn btn-default"
                                            (click)="agregarAlertaClienteVisible = !agregarAlertaClienteVisible">Cerrar</button>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-12">
                            <div class="card-header border-0">
                                <h2 class="h2">Envios de reportes de consumo de folios</h2>
                                <br />
                                <div>
                                <div class="table-responsive">
                                    <table class="table align-items-center table-flush">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>Cuando</th>
                                                <th>Fecha</th>
                                                <th>Mensaje</th>
                                            </tr>
                                        </thead>
                                        <tbody class="list">
                                            <tr *ngFor="let accion of this.enviosFolios">
                                                <td>hace {{ this.calcularDiasDesde(accion.fechaCreacion) }} días</td>
                                                <td>{{ accion.fechaCreacion }}</td>
                                                <td>{{ accion.mensaje }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>