import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/servicio.clientes';

@Component({
  selector: 'app-cliente-lista',
  templateUrl: './cliente-lista.component.html',
  styleUrls: ['./cliente-lista.component.css']
})
export class ClienteListaComponent implements OnInit {

  mostrarInactivas: boolean;
  clientes: Array<any>;

  constructor(
    private clientesService: ClientesService
    ) { }

  ngOnInit(): void {
    this.clientes = [];
    this.clientesService.getClientes().subscribe(res => {
      this.clientes = res.datos;
      console.log(this.clientes);
    });
  }

  filtrarPorEstado(clientes: Array<any>, estaBloqueado: boolean) : Array<any> {
    return this.clientes.filter(c => c.estaBloqueado == estaBloqueado);
  }

}
