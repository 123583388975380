<!-- Header -->
<div class="header bg-primary pb-6">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h2 class="h2 text-white d-inline-block mb-0">Transacciones</h2>
                    <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item"><a href="#"><i class="fas fa-home"></i></a></li>
                <li class="breadcrumb-item"><a href="#">Tables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Tables</li>
              </ol>
            </nav>-->
                </div>
                <div class="col-lg-6 col-5 text-right">
                    <div class="input-group">
                        <select style="width: 200px"  class="form-control" [(ngModel)]="this.anioSeleccionado" (change)="anioCambiado()">
                            <option value="{{ i }}" *ngFor="let i of this.aniosPosibles">{{ i }}</option>
                        </select>
                        <div class="input-group-append">    
                            <a (click)="agregarTransaccionVisible = !agregarTransaccionVisible" class="form-control btn btn-lg btn-neutral">Nuevo</a>
                        </div>
                      </div>





                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--6">
    
    <div class="row">
        <div class="col">
            <div class="card"  *ngIf="agregarTransaccionVisible">
                <!-- Card header -->
                <div class="card-header border-0">
                    <h3 class="mb-0">Agregar Transaccion</h3>
                    <p>Complete los siguientes campos para agregar una nueva transaccion.</p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Tipo</label>
                                <select name="Tipo" class="form-control" [(ngModel)]="this.transaccionTemporal.tipo">
                                    <option value="0">Egreso</option>
                                    <option value="1">Ingreso</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="this.transaccionTemporal.tipo == 1" class="col-md-12">
                            <div class="form-group">
                                <label>Cliente</label>
                                <select name="Mes" class="form-control" [(ngModel)]="this.transaccionTemporal.cliente.rut">
                                    <option *ngFor="let cliente of this.clientes" [ngValue]="cliente.rut">{{cliente.alias}} - {{cliente.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Forma de Pago</label>
                                <select name="FormaDePago" class="form-control" 
                                        [(ngModel)]="this.transaccionTemporal.formaDePago">
                                    <option [ngValue]="0">Efectivo</option>
                                    <option [ngValue]="1">Cheque</option>
                                    <option [ngValue]="2">Transferencia</option>
                                    <option [ngValue]="3">Otro</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="this.transaccionTemporal.tipo == 1" class="col-md-12">
                            <div class="form-group">
                                <label>Mes</label>
                                <select name="Mes" class="form-control"
                                                [(ngModel)]="this.transaccionTemporal.periodoMes">
                                                <option [ngValue]="1">1</option>
                                                <option [ngValue]="2">2</option>
                                                <option [ngValue]="3">3</option>
                                                <option [ngValue]="4">4</option>
                                                <option [ngValue]="5">5</option>
                                                <option [ngValue]="6">6</option>
                                                <option [ngValue]="7">7</option>
                                                <option [ngValue]="8">8</option>
                                                <option [ngValue]="9">9</option>
                                                <option [ngValue]="10">10</option>
                                                <option [ngValue]="11">11</option>
                                                <option [ngValue]="12">12</option>
                                            </select>
                            </div>
                        </div>
                        <div *ngIf="this.transaccionTemporal.tipo == 1" class="col-md-12">
                            <div class="form-group">
                                <label>Año</label>
                                <select name="Anio" class="form-control" [(ngModel)]="this.transaccionTemporal.periodoAnio">
                                    <option *ngFor="let anio of this.aniosPosibles" [ngValue]="anio">{{anio}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Monto</label>
                                <input type="number" name="Monto" class="form-control" 
                                    [(ngModel)]="this.transaccionTemporal.monto" placeholder="Monto" value="">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observaciones</label>
                                <textarea rows="10" name="Observaciones" class="form-control"
                                    placeholder="Observaciones"
                                    [(ngModel)]="this.transaccionTemporal.observaciones"></textarea>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="button" (click)="agregar()"
                                class="btn btn-info btn-fill pull-right">Guardar</button>
                            <button type="button" class="btn btn-default"
                                (click)="agregarTransaccionVisible = !agregarTransaccionVisible">Cerrar</button>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <!-- Card header -->
                <div class="card-header border-0">
                    <h3 class="mb-0">Pagos Recibidos</h3>
                    <p>Registro de todos los pagos recibidos por los clientes de Condor ERP.</p>
                </div>

                <!-- Light table -->
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Cliente</th>
                                <th scope="col">Periodo</th>
                                <th scope="col">Fecha Pago</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Forma Pago</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="list">
                            <tr *ngFor="let pago of this.pagos">
                                <th scope="row">
                                    <div class="align-items-center">
                                        {{ pago.cliente.alias }}
                                    </div>
                                </th>
                                <td class="budget">
                                    {{ pago.periodoAnio }} - {{ pago.periodoMes }}
                                </td>
                                <td>
                                    {{ pago.fechaPago | date:'dd/MM/yyyy HH:mm' }}
                                </td>
                                <td>
                                    {{ pago.monto | number: '2.' }}
                                </td>
                                <td>
                                    <span *ngIf="pago.formaDePago == 0" class="badge badge-dot mr-4">
                                        <span class="status">Efectivo</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 1" class="badge badge-dot mr-4">
                                        <span class="status">Cheque</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 2" class="badge badge-dot mr-4">
                                        <span class="status">Transferencia</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 3" class="badge badge-dot mr-4">
                                        <span class="status">Otro</span>
                                    </span>
                                </td>
                                <td class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                                        Acciones <span class="fa fa-chevron-down"></span>
                                    </button>
                                    <mat-menu #menuAcciones="matMenu">
                                        <button mat-menu-item (click)="eliminarPago(pago)"><i class="fa fa-trash"></i> Eliminar</button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- paginacion -->
                <!-- <div class="card-footer py-4">
                    <nav aria-label="...">
                        <ul class="pagination justify-content-end mb-0">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1">
                                    <i class="fas fa-angle-left"></i>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="fas fa-angle-right"></i>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div> -->
            </div>

            <hr />

            <div class="card">
                <!-- Card header -->
                <div class="card-header border-0">
                    <h3 class="mb-0">Pagos Realizados</h3>
                    <p>Registro de todos los pagos realizados para el correcto funcionamiento de Condor ERP.</p>
                </div>

                <!-- Light table -->
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Fecha Pago</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Observaciones</th>
                                <th scope="col">Forma Pago</th>
                                <th scope="col">Observaciones</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="list">
                            <tr *ngFor="let pago of this.egresos">
                                <td>
                                    {{ pago.fecha  | date:'dd/MM/yyyy HH:mm' }}
                                </td>
                                <td>
                                    {{ pago.monto | number: '2.' }}
                                </td>
                                <td>
                                    {{pago.observaciones  | slice:0:15}} <span *ngIf="pago.observaciones.length > 14">...</span>
                                </td>
                                <td>
                                    <span *ngIf="pago.formaDePago == 0" class="badge badge-dot mr-4">
                                        <span class="status">Efectivo</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 1" class="badge badge-dot mr-4">
                                        <span class="status">Cheque</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 2" class="badge badge-dot mr-4">
                                        <span class="status">Transferencia</span>
                                    </span>
                                    <span *ngIf="pago.formaDePago == 3" class="badge badge-dot mr-4">
                                        <span class="status">Otro</span>
                                    </span>
                                </td>
                                <td>
                                    {{ pago.observaciones }}
                                </td>
                                <td class="text-right">
                                    <button type="button" class="btn btn-sm btn-primary" mat-button [matMenuTriggerFor]="menuAcciones">
                                        Acciones <span class="fa fa-chevron-down"></span>
                                    </button>
                                    <mat-menu #menuAcciones="matMenu">
                                        <button mat-menu-item (click)="eliminarEgreso(pago)"><i class="fa fa-trash"></i> Eliminar</button>
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- paginacion -->
                <!-- <div class="card-footer py-4">
                    <nav aria-label="...">
                        <ul class="pagination justify-content-end mb-0">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1">
                                    <i class="fas fa-angle-left"></i>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="fas fa-angle-right"></i>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div> -->
            </div>

            <hr />

            <div class="card">
                <div class="card-header border-0">
                    <h3 class="mb-0">Totales</h3>
                    <p>Muestra los resultados de los egresos e ingresos.</p>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <h3>Total egresos: {{ totalEgresos() | number: '2.'  }}</h3>
                            <h3>Total recibido: {{ totalPagos() | number: '2.'  }}</h3>
                            <hr />
                            <h3>Ganancias: {{ totalPagos() - totalEgresos() | number: '2.'  }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>