import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserDesktopComponent } from './user-desktop/user-desktop.component';
import { CuentasService } from '../services/servicio.cuenta';
import { SharedModule } from '../shared/shared.module';
import { NotificacionesService } from '../services/servicio.notificaciones';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [UserLoginComponent, UserDesktopComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatDialogModule
  ],
  providers: [
    CuentasService,
    NotificacionesService
  ]
})
export class UsersModule { }
