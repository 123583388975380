import { Component, OnInit } from '@angular/core';
import { AyudaService } from 'src/app/services/servicio.ayuda';

@Component({
  selector: 'app-ayuda-lista',
  templateUrl: './ayuda-lista.component.html',
  styleUrls: ['./ayuda-lista.component.css']
})
export class AyudaListaComponent implements OnInit {

  articulos: Array<any>;

  constructor(
    private ayudaService: AyudaService
    ) { }

  ngOnInit(): void {
    this.articulos = [];
    this.ayudaService.getArticulos('',1,2000).subscribe(res => {
      this.articulos = res.datos;
      console.log(this.articulos);
    });
  }


}
