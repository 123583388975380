import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TransaccionesService {
    urlApi: string;

    constructor (
        private http: HttpClient
    ) {
        this.urlApi = environment.apiCondorURL;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
          
        return new HttpHeaders(headerDict);
    }

    
    public getPagos(anio: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Transacciones/Pagos?anio=${anio}`, options);
    }

    public agregarPago(pago: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Transacciones/RegistrarPago`, pago, options);
    }

    public eliminarPago(pago: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Transacciones/EliminarPago`, pago, options);
    }


    public getEgresos(anio: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.get(`${this.urlApi}/Transacciones/Egresos?anio=${anio}`, options);
    }

    public agregarEgresos(egreso: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Transacciones/RegistrarEgreso`, egreso, options);
    }

    public eliminarEgresos(egreso: any) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        
        return this.http.post(`${this.urlApi}/Transacciones/EliminarEgreso`, egreso, options);
    }
}