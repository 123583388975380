export class Notificacion
{
    public Titulo: string;
    public Mensaje: string;
    public Nivel: string;

    public estaDescartado: boolean;

    constructor(Titulo :string, Mensaje :string, Nivel :string = "Info")
    {
        this.Titulo = Titulo;
        this.Mensaje = Mensaje;
        this.Nivel = Nivel;
        this.estaDescartado = false;
    }
}