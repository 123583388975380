<div *ngIf="this.articulo">
    <!-- Header -->
    <div class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h2 class="h2 text-white d-inline-block mb-0">Modificando Articulo</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row">
                        <div class="card-body">
                            <div class="col-lg-12">
                                <h3>{{this.articulo.titulo}}</h3>
                            </div>
                            

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Titulo</label>
                                    <input [(ngModel)]="this.articulo.titulo" class="form-control">
                                </div>
                            </div>
                            
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Habilitado</label>
                                    <select name="Habilitado" class="form-control"
                                        [(ngModel)]="this.articulo.habilitado">
                                        <option [ngValue]="true">Si</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Texto</label>
                                    <textarea class="form-control" style="height: 300px;" [(ngModel)]="this.articulo.texto"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <button class="btn-neutral" (click)="guardar()">Guardar</button>
                                <button class="btn-neutral" [routerLink]="['/home/ayuda/ayuda-detalle', articulo.id]">Volver</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>