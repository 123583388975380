<!-- Header -->
<div class="header bg-primary pb-6">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h2 class="h2 text-white d-inline-block mb-0">Scripts</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--6">
    <div class="row">
        <div class="col">
            <div class="card">
                <!-- Card header -->
                <div class="card-header border-0">
                    <h3 class="mb-0">Scripts de Condor ERP</h3>
                    <p>Estas son las opciones </p>

                    <select [(ngModel)]="this.scriptSeleccionado" class="form-control" (change)="onScriptChange($event.target.value)">
                        <option *ngFor="let script of this.scripts" [value]="script.nombre" > {{ script.nombre }}</option>
                    </select>
                
                    <ng-container *ngIf="this.mostrarClientes">
                        <select [(ngModel)]="this.clienteSeleccionado" class="form-control">
                            <option *ngFor="let cliente of this.clientes" [value]="cliente.alias" > {{ cliente.alias }}</option>
                        </select>
                    </ng-container>
                    
                    <a class="btn btn-success" (click)="this.ejecutar()">Ejecutar!</a>


                    <ng-container *ngIf="this.ultimoResultado">
                        <hr>
                        <h3>Ultimo Resultado</h3>

                        <div>¿Es Correcto?: {{ this.ultimoResultado.esCorrecto }}</div>
                        <div [innerHTML]="this.ultimoResultado.datos"></div>
                    </ng-container>








                </div>

                
            </div>
        </div>
    </div>
</div>