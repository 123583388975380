<div *ngIf="this.cliente">
    <!-- Header -->
    <div class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h2 class="h2 text-white d-inline-block mb-0">Eliminando Cliente: {{ this.cliente.alias }}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--6">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="h2 card-header">¿Esta seguro que quiere eliminar el siguiente cliente?</h2>
                        </div>
                        <div class="col-lg-6">
                            <!-- Card header -->
                            <div class="card-header border-0">
                                <p>RUT</p>
                                <h3 class="mb-0">{{ this.cliente.rut }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Nombre</p>
                                <h3 class="mb-0">{{ this.cliente.nombre }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Alias</p>
                                <h3 class="mb-0">{{ this.cliente.alias }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Plan</p>
                                <h3 class="mb-0">{{ this.cliente.plan }}</h3>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>¿Está bloqueado?</p>

                                <span *ngIf="cliente.estaBloqueado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-warning"></i> Si</h3>

                                </span>
                                <span *ngIf="!cliente.estaBloqueado" class="badge badge-dot mr-4">
                                    <h3 class="mb-0 status"><i class="bg-success"></i> No</h3>

                                </span>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="card-header border-0">
                                <p>Email</p>
                                <h3 class="mb-0">{{ this.cliente.email }}</h3>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div style="padding: 20px 20px 20px 20px">
                                <a class="btn btn-danger" (click)="eliminar()">Si, eliminar</a>
                                <a class="btn btn-success" (click)="regresar()">No, regresar</a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>