import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AyudaService } from 'src/app/services/servicio.ayuda';

@Component({
  selector: 'app-ayuda-detalle',
  templateUrl: './ayuda-detalle.component.html',
  styleUrls: ['./ayuda-detalle.component.css']
})
export class AyudaDetalleComponent implements OnInit {

  articulo: any; 
  constructor(
    private ayudaService: AyudaService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    var id = 1;
    this.route.params.subscribe(params => {
      var id = params['id'];
      this.ayudaService.getArticulo(id).subscribe(res => {
        this.articulo = res.datos;
      });
    });
  }

}
