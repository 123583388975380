<div class="row">
    <div class="col-xs-12">
      <h1 mat-dialog-title><i class="fa fa-times"></i> Reporte de boletas</h1>
      <div mat-dialog-content>
        <p>Ingrese el dia del cual quiere enviar el reporte de boletas</p>
        <form>
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">Dia</label>
              <div class="col-sm-10">
                <input type="number"  name="dia" class="form-control"  [(ngModel)]="this.dia">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">Mes</label>
              <div class="col-sm-10">
                <input type="number"  name="mes"  class="form-control"  [(ngModel)]="this.mes">
              </div>
            </div>
            <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label">Año</label>
                <div class="col-sm-10">
                    <input type="number" name="anio"  class="form-control"  [(ngModel)]="this.anio">
                </div>
              </div>
          </form>

        
      </div>
      <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="{ result: { response: 'ok', dia: this.dia, mes: this.mes - 1, anio: this.anio, cliente: this.cliente } }" class="btn btn-danger">Enviar</button>
        <button mat-button class="btn btn-default" (click)="onNoClick()">Cancelar</button>
      </div>
    </div>
  </div>