import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AyudaDetalleComponent } from './ayuda/ayuda-detalle/ayuda-detalle.component';
import { AyudaListaComponent } from './ayuda/ayuda-lista/ayuda-lista.component';
import { AyudaModificarComponent } from './ayuda/ayuda-modificar/ayuda-modificar.component';
import { ClienteDetalleComponent } from './clientes/cliente-detalle/cliente-detalle.component';
import { ClienteEliminarComponent } from './clientes/cliente-eliminar/cliente-eliminar.component';
import { ClienteListaComponent } from './clientes/cliente-lista/cliente-lista.component';
import { ClienteModificarComponent } from './clientes/cliente-modificar/cliente-modificar.component';
import { PrivateComponent } from './home/private/private.component';
import { PublicComponent } from './home/public/public.component';
import { TransaccionListaComponent } from './transacciones/transaccion-lista/transaccion-lista.component';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { EjecutarScriptComponent } from './admin/ejecutar-script/ejecutar-script.component';


export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'public/users/user-login',
    pathMatch: 'full'
  },
  {
      path: 'public',
      component: PublicComponent,
      children: [
          {
              path: '',
              redirectTo: 'users/user-login',
              pathMatch: 'full'
          },
          {
              path: 'users/user-login',
              component: UserLoginComponent
          }
      ]
  },
  {
    path: 'home',
    component: PrivateComponent,
    children: [
        {
            path: '',
            redirectTo: 'clientes/cliente-lista',
            pathMatch: 'full'
        },
        {
            path: 'clientes/cliente-lista',
            component: ClienteListaComponent
        },
        {
            path: 'clientes/cliente-detalle/:id',
            component: ClienteDetalleComponent
        },
        {
            path: 'clientes/cliente-eliminar/:id',
            component: ClienteEliminarComponent
        },
        {
            path: 'clientes/cliente-modificar/:id/:accion',
            component: ClienteModificarComponent
        },
        {
            path: 'transacciones/transaccion-lista/:anio',
            component: TransaccionListaComponent
        },
        {
            path: 'ayuda/ayuda-lista',
            component: AyudaListaComponent
        },
        {
            path: 'ayuda/ayuda-detalle/:id',
            component: AyudaDetalleComponent
        },
        {
            path: 'ayuda/ayuda-modificar/:id',
            component: AyudaModificarComponent
        },
        {
            path: 'admin/script-ejecutar',
            component: EjecutarScriptComponent
        }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
