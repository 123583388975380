import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransaccionListaComponent } from './transaccion-lista/transaccion-lista.component';
import { TransaccionesService } from '../services/servicio.transacciones';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { ClientesService } from '../services/servicio.clientes';

@NgModule({
  declarations: [TransaccionListaComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule
  ],
  providers: [TransaccionesService, ClientesService]
})
export class TransaccionesModule { }
