import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CuentasService } from 'src/app/services/servicio.cuenta';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css']
})
export class PrivateComponent implements OnInit {
  anioSeleccionado: number;

  constructor(private cuentasService: CuentasService,
    private router: Router) { }

  ngOnInit(): void {
    this.anioSeleccionado = new Date().getFullYear();
  }

  salir() : void {
    this.cuentasService.cerrarSesion();
    this.router.navigate(['public/users/user-login']);
  }
}
