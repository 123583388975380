import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    NotificacionesComponent,
    InfoPopupComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [NotificacionesComponent]
})
export class SharedModule { }
