import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { appRoutes } from './app-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from '../app/home/public/public.component';
import { PrivateComponent } from '../app/home/private/private.component';
import { InterceptorAutentificacion } from './services/interceptores/interceptor.autentificacion';
import { Router, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UsersModule } from './users/users.module';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { ClientesModule } from './clientes/clientes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransaccionesModule } from './transacciones/transacciones.module';
import { AyudaModule } from './ayuda/ayuda.module';

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    PrivateComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    UsersModule,
    SharedModule,
    ClientesModule,
    TransaccionesModule,
    AyudaModule,
    AdminModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useFactory: authHttpServiceInterceptor,
    multi: true,
    deps: [Router],
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function authHttpServiceInterceptor(router: Router) {
  return new InterceptorAutentificacion(router);
}